var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"editDomainForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{attrs:{"id":"editDomainForm"},on:{"submit":function($event){$event.preventDefault();return _vm.updateDomain(_vm.domainId)}}},[_c('b-container',[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 159, 67, 0.15)'),expression:"'rgba(255, 159, 67, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"outline-warning","to":"/domains","title":"Go Back"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"20"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"10"}},[_c('h1',[_vm._v("Edit the Domain")])])],1),_c('hr'),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-form-group',{attrs:{"label":"Account Name: *","label-for":"d-name"}},[_c('validation-provider',{attrs:{"name":"Account Name","rules":"required|regex:^[a-zA-Z].*$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"d-name","state":errors.length > 0 ? false : null,"placeholder":"Domain Name","autofocus":""},model:{value:(_vm.domain_name),callback:function ($$v) {_vm.domain_name=$$v},expression:"domain_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Event URL","rules":"url"}},[_c('b-form-group',{attrs:{"label":"Account Link: *","label-for":"event-url"}},[_c('validation-provider',{attrs:{"name":"Account Link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"event-url","type":"url","placeholder":"https://www.example.com","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.domain_link),callback:function ($$v) {_vm.domain_link=$$v},expression:"domain_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('imgmng',{on:{"onInsertImage":_vm.insertInsideTemplate}}),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Default Page:","label-for":"did"}},[_c('editor',{attrs:{"api-key":"d9r45kzw4kckdcs6pkokgeeo1mtulu4s3htw9o68h01um91x","init":{
                  selector: '.editor',
                  plugins:
                    'fullpage code advlist link image preview lists wordcount',
                  bbcode_dialect: 'punbb',
                  height: 600,
                }},model:{value:(_vm.templateContent),callback:function ($$v) {_vm.templateContent=$$v},expression:"templateContent"}})],1)],1)],1),_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 159, 67, 0.15)'),expression:"'rgba(255, 159, 67, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"outline-warning","disabled":invalid}},[_vm._v(" Update ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.previewTemplateOfCampaign}},[_vm._v(" Preview ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }